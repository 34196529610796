import React, { useEffect, useState } from 'react';
import NamedLink from '../../components/NamedLink/NamedLink';
import work1 from '../../assets/work1.png';
import banner from '../../assets/banner.png';
import classNames from 'classnames';
import Icons from '../../components/Icons/Icons';
import css from './EventsMenopauseNavBar.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopics, fetchTopicsData } from '../../ducks/strapi.duck';
import { isArrayLength } from '../../util/genericHelpers';
import { useIntl } from 'react-intl';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

const getSideTopicsMenu = (topics, intl) => {
  return [
    {
      title: intl.formatMessage({ id: 'EventsMenopauseNavBar.topics' }),
      description: (
        <div className={css.subContent}>
          {topics?.map((topic, index) => {
            const topicAttributes = topic?.attributes;
            const bannerImage = topicAttributes?.bannerLogo?.data?.attributes?.url;
            return (
              <NamedLink
                key={index}
                name="TopicDetailsPage"
                params={{ slug: topicAttributes?.title }}
              >
                <span>
                  <img src={bannerImage} alt="img" />
                </span>
                {topicAttributes?.title}
              </NamedLink>
            );
          })}
        </div>
      ),
    },
  ];
};

function EventsMenopauseNavBar(props) {
  const { viewport } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [activeIndex, setActiveIndex] = useState(null);
  const handleAccordionClick = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  useEffect(() => {
    dispatch(fetchTopics());
  }, []);
  const { topics, fetchTopicsInProgress } = useSelector(fetchTopicsData) || {};
  const isMobileLayout = viewport.width > 0 && viewport.width < 1024;
  const [toggleMobileMenu, onToggleMobileMenu] = useState(false);

  return (
    <>
      {isMobileLayout ? (
        <div className={css.mobileMenuToggleWrapper}>
          <div
            className={classNames(css.mobileMenuToggle, {
              [css.expanded]: toggleMobileMenu,
            })}
            onClick={() => onToggleMobileMenu(!toggleMobileMenu)}
          >
            {intl.formatMessage({ id: 'EventsMenopauseNavBar.menu' })}{' '}
            {toggleMobileMenu ? <Icons name="cross" /> : <Icons name="menu" />}
          </div>
          {toggleMobileMenu && (
            <div className={css.dashboardSidebar}>
              {isArrayLength(topics)
                ? getSideTopicsMenu(topics, intl)?.map((faq, index) => (
                    <div
                      key={index}
                      className={classNames(css.faqCard, {
                        [css.activeFAQ]: activeIndex === index,
                      })}
                      onClick={() => handleAccordionClick(index)}
                    >
                      <div className={css.faqTitle}>
                        <span className={css.faqIcon}>
                          <Icons name="guide" />
                        </span>
                        <span className={css.faqHeading}>
                          {intl.formatMessage({ id: 'EventsMenopauseNavBar.guides' })}
                        </span>
                      </div>
                      {activeIndex === index && (
                        <div className={css.faqDescription}>{faq?.description}</div>
                      )}
                    </div>
                  ))
                : null}
              <div
                className={css.upcomingEvent}
                role="button"
                onClick={() =>
                  history.push(
                    createResourceLocatorString(
                      'PatientHomepage',
                      routeConfiguration(),
                      {},
                      { search: 'menopause' }
                    )
                  )
                }
              >
                <Icons name="dashboardHome" />{' '}
                {intl.formatMessage({ id: 'EventsMenopauseNavBar.menopauseProgram' })}
              </div>
              <div
                className={css.upcomingEvent}
                role="button"
                onClick={() =>
                  history.push(
                    createResourceLocatorString(
                      'PatientHomepage',
                      routeConfiguration(),
                      {},
                      { search: 'courses' }
                    )
                  )
                }
              >
                <Icons name="dashboardHome" />{' '}
                {intl.formatMessage({ id: 'EventsMenopauseNavBar.videoCourses' })}
              </div>
              <div
                className={classNames(css.upcomingEvent)}
                role="button"
                onClick={() =>
                  history.push(
                    createResourceLocatorString('EventsPage', routeConfiguration(), {}, {})
                  )
                }
              >
                <Icons name="calendar" />{' '}
                {intl.formatMessage({ id: 'EventsMenopauseNavBar.webinars' })}
              </div>
              {/* <div
                className={classNames(css.upcomingEvent)}
                role="button"
                onClick={() =>
                  history.push(
                    createResourceLocatorString('LandingPatientPage', routeConfiguration(), {}, {})
                  )
                }
              >
                <Icons name="phone" />{' '}
                {intl.formatMessage({ id: 'EventsMenopauseNavBar.bookSession' })}
              </div> */}
              <div
                className={classNames(css.upcomingEvent)}
                role="button"
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    window.open('https://www.facebook.com/groups/448217043157118/', '_blank');
                  }
                }}
              >
                <Icons name="phone" />{' '}
                {intl.formatMessage({ id: 'EventsMenopauseNavBar.community' })}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={css.dashboardSidebar}>
          {isArrayLength(topics)
            ? getSideTopicsMenu(topics, intl)?.map((faq, index) => (
                <div
                  key={index}
                  className={classNames(css.faqCard, {
                    [css.activeFAQ]: activeIndex === index,
                  })}
                  onClick={() => handleAccordionClick(index)}
                >
                  <div className={css.faqTitle}>
                    <span className={css.faqIcon}>
                      <Icons name="guide" />
                    </span>
                    <span className={css.faqHeading}>
                      {intl.formatMessage({ id: 'EventsMenopauseNavBar.guides' })}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div className={css.faqDescription}>{faq?.description}</div>
                  )}
                </div>
              ))
            : null}
          <div
            className={css.upcomingEvent}
            role="button"
            onClick={() =>
              history.push(
                createResourceLocatorString(
                  'PatientHomepage',
                  routeConfiguration(),
                  {},
                  { search: 'menopause' }
                )
              )
            }
          >
            <Icons name="dashboardHome" />{' '}
            {intl.formatMessage({ id: 'EventsMenopauseNavBar.menopauseProgram' })}
          </div>
          <div
            className={css.upcomingEvent}
            role="button"
            onClick={() =>
              history.push(
                createResourceLocatorString(
                  'PatientHomepage',
                  routeConfiguration(),
                  {},
                  { search: 'courses' }
                )
              )
            }
          >
            <Icons name="videoOutlineIcon" />{' '}
            {intl.formatMessage({ id: 'EventsMenopauseNavBar.videoCourses' })}
          </div>
          <div
            className={classNames(css.upcomingEvent)}
            role="button"
            onClick={() =>
              history.push(createResourceLocatorString('EventsPage', routeConfiguration(), {}, {}))
            }
          >
            <Icons name="calendar" /> {intl.formatMessage({ id: 'EventsMenopauseNavBar.webinars' })}
          </div>
          <div
            className={classNames(css.upcomingEvent)}
            role="button"
            onClick={() =>
              history.push(
                createResourceLocatorString('LandingPatientPage', routeConfiguration(), {}, {})
              )
            }
          >
            <Icons name="phone" /> {intl.formatMessage({ id: 'EventsMenopauseNavBar.bookSession' })}
          </div>
          <div
            className={classNames(css.upcomingEvent)}
            role="button"
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.open('https://www.facebook.com/groups/448217043157118/', '_blank');
              }
            }}
          >
            <Icons name="doctors" /> {intl.formatMessage({ id: 'EventsMenopauseNavBar.community' })}
          </div>
        </div>
      )}
    </>
  );
}

export default EventsMenopauseNavBar;
