import {
  fetchTagsFilter,
  fetchTutorials,
  getEvent,
  getEvents,
  getSymptoms,
  getTopicDetails,
  getTopics,
  getTutorialSubcategories,
} from '../util/api';
import { getDefaultLocale, isArrayLength } from '../util/genericHelpers';

export const FETCH_TUTORIALS_REQUEST = 'app/strapi/FETCH_TUTORIALS_REQUEST';
export const FETCH_TUTORIALS_SUCCESS = 'app/strapi/FETCH_TUTORIALS_SUCCESS';
export const FETCH_TUTORIALS_ERROR = 'app/strapi/FETCH_TUTORIALS_ERROR';

export const FETCH_EVENTS_REQUEST = 'app/strapi/FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'app/strapi/FETCH_EVENTS_SUCCESS';

// Single Event Detail
export const FETCH_EVENT_REQUEST = 'app/strapi/FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'app/strapi/FETCH_EVENT_SUCCESS';

export const FETCH_TOPIC_REQUEST = 'app/strapi/FETCH_TOPIC_REQUEST';
export const FETCH_TOPIC_SUCCESS = 'app/strapi/FETCH_TOPIC_SUCCESS';
export const FETCH_TOPIC_DETAILS_REQUEST = 'app/strapi/FETCH_TOPIC_DETAILS_REQUEST';
export const FETCH_TOPIC_DETAILS_SUCCESS = 'app/strapi/FETCH_TOPIC_DETAILS_SUCCESS';

export const FETCH_TUTORIALS_SUBCATEGORIES_REQUEST =
  'app/strapi/FETCH_TUTORIALS_SUBCATEGORIES_REQUEST';
export const FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS =
  'app/strapi/FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS';
export const FETCH_SYMPTOMS_SUCCESS = 'app/strapi/FETCH_SYMPTOMS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  fetchTutorialsInProgress: false,
  tutorials: [],
  modules: [],
  fetchTutorialsError: false,
  event: null,
  fetchEventInProgress: false,
  events: [],
  fetchEventsInProgress: false,
  topics: [],
  fetchTopicsInProgress: false,
  topicDetails: [],
  fetchTopicDetailsInProgress: false,
  fetchTutorialsSubcategoriesInProgress: false,
  tutorialsSubcategories: [],
  symptoms: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SYMPTOMS_SUCCESS:
      return {
        ...state,
        symptoms: payload,
      };
    case FETCH_TUTORIALS_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        fetchTutorialsSubcategoriesInProgress: true,
      };
    case FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        tutorialsSubcategories: payload,
        fetchTutorialsSubcategoriesInProgress: false,
      };
    case FETCH_TOPIC_REQUEST:
      return {
        ...state,
        fetchTopicsInProgress: true,
      };
    case FETCH_TOPIC_SUCCESS:
      return {
        ...state,
        topics: payload,
        fetchTopicsInProgress: false,
      };
    case FETCH_TOPIC_DETAILS_REQUEST:
      return {
        ...state,
        fetchTopicDetailsInProgress: true,
      };
    case FETCH_TOPIC_DETAILS_SUCCESS:
      return {
        ...state,
        topicDetails: payload,
        fetchTopicDetailsInProgress: false,
      };
    case FETCH_EVENT_REQUEST:
      return {
        ...state,
        fetchEventInProgress: true,
      };
    case FETCH_EVENT_SUCCESS:
      return {
        ...state,
        event: payload,
        fetchEventInProgress: false,
      };
    case FETCH_EVENTS_REQUEST:
      return {
        ...state,
        fetchEventsInProgress: true,
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        fetchEventsInProgress: false,
        events: payload,
      };
    case FETCH_TUTORIALS_REQUEST:
      return {
        ...state,
        fetchTutorialsInProgress: true,
      };
    case FETCH_TUTORIALS_SUCCESS:
      return {
        ...state,
        fetchTutorialsInProgress: false,
        tutorials: payload?.tutorials,
        modules: payload?.modules,
      };
    case FETCH_TUTORIALS_ERROR:
      return {
        ...state,
        fetchTutorialsInProgress: false,
        fetchTutorialsError: true,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const fetchTutorialsData = state => {
  return state.strapi.tutorials;
};
export const fetchModulesData = state => {
  return state.strapi.modules;
};

export const fetchTutorialsInProgress = state => {
  return state.strapi.fetchTutorialsInProgress;
};

export const fetchTutorialsErrors = state => {
  return state.strapi.fetchTutorialsError;
};

export const fetchEventsData = state => {
  return {
    events: state.strapi.events,
    inProgress: state.strapi.fetchEventsInProgress,
  };
};

export const fetchEventData = state => {
  return {
    event: state.strapi.event,
    inProgress: state.strapi.fetchEventInProgress,
  };
};

export const fetchTopicsData = state => {
  return {
    topics: state.strapi.topics,
    fetchTopicsInProgress: state.strapi.fetchTopicsInProgress,
  };
};

export const fetchTopicsDetailsData = state => {
  return {
    topicDetails: state.strapi.topicDetails,
    fetchTopicDetailsInProgress: state.strapi.fetchTopicDetailsInProgress,
  };
};

export const fetchTutorialsSubcategoriesData = state => {
  return {
    symptoms: state.strapi.symptoms,
    tutorialsSubcategories: state.strapi.tutorialsSubcategories,
    fetchTutorialsSubcategoriesInProgress: state.strapi.fetchTutorialsSubcategoriesInProgress,
  };
};

// ================ Action creators ================ //

export const fetchTutorialsRequest = () => ({
  type: FETCH_TUTORIALS_REQUEST,
});

export const fetchTutorialsSuccess = payload => ({
  type: FETCH_TUTORIALS_SUCCESS,
  payload,
});

export const fetchTutorialsError = () => ({
  type: FETCH_TUTORIALS_ERROR,
});

export const fetchEventsRequest = () => ({
  type: FETCH_EVENTS_REQUEST,
});

export const fetchEventsSuccess = payload => ({
  type: FETCH_EVENTS_SUCCESS,
  payload,
});

export const fetchEventRequest = () => ({
  type: FETCH_EVENT_REQUEST,
});

export const fetchEventSuccess = payload => ({
  type: FETCH_EVENT_SUCCESS,
  payload,
});

export const fetchTopicsRequest = () => ({
  type: FETCH_TOPIC_REQUEST,
});

export const fetchTopicsSuccess = payload => ({
  type: FETCH_TOPIC_SUCCESS,
  payload,
});
export const fetchTopicsDetailsRequest = () => ({
  type: FETCH_TOPIC_DETAILS_REQUEST,
});

export const fetchTopicsDetailsSuccess = payload => ({
  type: FETCH_TOPIC_DETAILS_SUCCESS,
  payload,
});

export const fetchTutorialSubcategoriesRequest = () => ({
  type: FETCH_TUTORIALS_SUBCATEGORIES_REQUEST,
});

export const fetchTutorialSubcategoriesSuccess = payload => ({
  type: FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS,
  payload,
});

export const fetchSymptomsSuccess = payload => ({
  type: FETCH_SYMPTOMS_SUCCESS,
  payload,
});

// ================ Thunks ================ //

export const fetchVideoTutorials = params => async (dispatch, getState, sdk) => {
  dispatch(fetchTutorialsRequest());
  const defaultLocale = getDefaultLocale() == 'de' ? 'el-GR' : 'en';
  try {
    const [tutorialsResponse, tagsResponse] = await Promise.all([
      fetchTutorials({ defaultLocale, ...params }),
      fetchTagsFilter({ isTutorials: true }),
    ]);

    dispatch(fetchTutorialsSuccess(tutorialsResponse));
  } catch (error) {
    dispatch(fetchTutorialsError());
  }
};

export const fetchEvents = () => async (dispatch, getState, sdk) => {
  dispatch(fetchEventsRequest());
  try {
    const { events = {} } = (await getEvents({})) || {};
    dispatch(fetchEventsSuccess(events));
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchEvent = params => async (dispatch, getState, sdk) => {
  dispatch(fetchEventRequest());
  try {
    const { event = [] } = (await getEvent({ ...params })) || {};
    dispatch(fetchEventSuccess(isArrayLength(event) && event[0]));
    return event;
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchTopics = () => async (dispatch, getState, sdk) => {
  dispatch(fetchTopicsRequest());
  try {
    const { topics = [] } = (await getTopics({})) || {};
    dispatch(fetchTopicsSuccess(topics));
    return topics;
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchTopicDetails = params => async (dispatch, getState, sdk) => {
  dispatch(fetchTopicsDetailsRequest());
  try {
    const { topics = [] } = (await getTopicDetails({ ...params })) || {};
    dispatch(fetchTopicsDetailsSuccess(topics));
    return topics;
  } catch (error) {
    console.log(error, 'Error while fetching topic details');
  }
};

export const fetchTutorialSubcategories = params => async (dispatch, getState, sdk) => {
  dispatch(fetchTutorialSubcategoriesRequest());
  try {
    const [tutorialsResponse, symptomsResponse] = await Promise.all([
      getTutorialSubcategories({ ...params }),
      getSymptoms({ ...params }),
    ]);

    const tutorials = tutorialsResponse?.tutorials || [];
    const symptoms = symptomsResponse?.symptoms || [];

    dispatch(fetchTutorialSubcategoriesSuccess(tutorials));
    dispatch(fetchSymptomsSuccess(symptoms));
    return tutorials;
  } catch (error) {
    console.error('Error while fetching tutorials subcategories:', error);
    // dispatch(fetchTutorialSubcategoriesFailure(error));
    return [];
  }
};
