export const PROVIDER = {
  LICENSE: 'license',
  RESUME: 'resume',
  DOCUMENTS: 'documents',
  SPECIALITY: 'speciality',
};

export const SENDGRID_CONTRACT_LIST = {
  ALL_PATIENTS: '6a86af51-68fc-4ecd-9ba0-bec2678673d2',
  BAYER_AND_JDE: 'b1d903ff-ae8e-440d-8299-ebf80a09ecb0',
  FREE_TRIAL: 'b1230b55-d2ab-4248-a8fc-5de2c70d1523',
};

export const MEETING_PANEL = {
  KICK: 'kick',
  LEAVE: 'leave',
};

export const DEFAULT_PRODUCT_NAME =
  'Βασικό ποσό: €100, ΦΠΑ (24%): €24, Συνολικό ποσό (συμπεριλαμβανομένου ΦΠΑ): €124';

export const USERTYPES = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
};
